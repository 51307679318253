import React, { useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import AppBar from './components/sections/AppBar.js';
import Home from './components/pages/Home.js';
import PrivacyPolicy from './components/pages/PrivacyPolicy.js';
import Footer from './components/sections/Footer.js';
import DeleteAccount from './components/pages/DeleteAccount.js';


function App() {

  const { t } = useTranslation();
  // const navigate = useNavigate();

  return (
    <Box>
      <Router>
        <div className="App">
          <Box width={"100%"} position={"fixed"} top={0} zIndex={9999}>
            <AppBar />
          </Box>

          <Routes>
            <Route path="" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/TermsAndConditions" element={<Home />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/DeleteAccount" element={<DeleteAccount />} />
            {/* Add more routes here as needed */}
          </Routes>

          <Box id="footer" py={7} pb={7} sx={{ width: '100%', backgroundColor: '#fff5f0' }}>
            <Container>
              <Footer />
            </Container>         
          </Box>
        </div>
      </Router>
    </Box>
  );
}

export default App;