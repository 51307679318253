import React, {useEffect } from 'react';


export default function DeleteAccount() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <img src="/images/DeleteAccountGuide.jpeg" alt="placeholder" />
    );


}